import { useState, useCallback, useEffect } from 'react'

export function useRemainingSeconds(time: number) {
  const [remainingSeconds, setRemainingSeconds] = useState<number | null>(null)

  const updateRemainingSeconds = useCallback(() => {
    setRemainingSeconds(Math.round((time - Date.now()) / 1000))
  }, [time])

  useEffect(() => {
    updateRemainingSeconds()

    const interval = setInterval(() => {
      updateRemainingSeconds()
    }, 1000)

    return () => clearInterval(interval)
  }, [updateRemainingSeconds])

  return remainingSeconds
}
