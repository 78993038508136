import { createContext } from 'react'

interface AuthContextValue {
  accessToken: string | null
  onUnauthenticated: () => void
}

export const AuthContext = createContext<AuthContextValue>({
  accessToken: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onUnauthenticated: () => {},
})
