import { DEFAULT_SLIDE_DURATION_MS } from './constants'
import { Slideshow } from './slideshow/Slideshow'

const slides = [
  {
    slideId: '1',
    media: {
      url: '1.jpg',
    },
  },
  {
    slideId: '2',
    media: {
      url: '2.jpg',
    },
  },
  {
    slideId: '3',
    media: {
      url: '3.jpg',
    },
  },
  {
    slideId: '4',
    media: {
      url: '4.jpg',
    },
  },
]

export function DemoSlideshow() {
  return (
    <Slideshow
      screenId="demo"
      slides={slides}
      slideDurationMs={DEFAULT_SLIDE_DURATION_MS}
    />
  )
}
