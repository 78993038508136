import { assign, createMachine, actions, DoneInvokeEvent } from 'xstate'
import { Client } from 'src/service/device-auth/client'
import { AuthorizationResponse, DeviceCodeInfoResponse } from 'src/types'
import {
  deleteDeviceCodeInfo,
  getDeviceCodeInfo,
  saveDeviceCodeInfo,
} from './persistence'

const { send, cancel } = actions

type DeviceCodeContextLoading = {
  retryCount: null
  nextRetryTime: null
  deviceCodeInfo: null
  authorizationResponse: null
}

type DeviceCodeContextRetry = {
  retryCount: number
  nextRetryTime: number
  deviceCodeInfo: null
  authorizationResponse: null
}

type DeviceCodeContextHasCode = {
  retryCount: null
  nextRetryTime: null
  deviceCodeInfo: DeviceCodeInfoResponse
  authorizationResponse: null
}

type DeviceCodeContextHasAuthorizationResponse = {
  retryCount: null
  nextRetryTime: null
  deviceCodeInfo: null
  authorizationResponse: AuthorizationResponse
}

type DeviceCodeContext =
  | DeviceCodeContextLoading
  | DeviceCodeContextRetry
  | DeviceCodeContextHasCode
  | DeviceCodeContextHasAuthorizationResponse

const ONE_SECOND = 1000
const ONE_MINUTE = 60 * ONE_SECOND
const FIFTEEN_SECONDS = 15 * ONE_SECOND
const FIVE_MINUTES = 5 * ONE_MINUTE

const DEFAULT_RETRY_DELAY = FIVE_MINUTES
const BASE_RETRY_DELAY = FIFTEEN_SECONDS

export const deviceCodeMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QTANwJYGMwGED2KAdADZ4CGEAygC54BOkAImlrgWAMQR4B2Yh6HqjwBrfigzZ8RUhRr0mLKewSDhmMtXS8A2gAYAuolAAHPLHRbexkAA9EAWgBsAZgCMhABwBOb07eeAOwATMEALK5OADQgAJ6OYQGEeol6wS7Bem7ebi6eYQC+BTESrNL8slS0DBDMkmwoHGB0dPSEJsSaAGb0ALaEpcoy5FUKtUoNYKpCeBpWPPpGSCBmFvM29ggO3gCsnoQ72d5hYYGBTnqBeTHxCG5uesnn3pkue8Fnek5FJRPlA392ABRWwmdA1Li8fhqUTiQFEQaTEFgmrTdSabQLQw2VaWTEbRzhJyEN6XPTkpzeFzk0I3RBhbyBQhhHauDJpQKeJzpH4gRH-fnA0HgyBNFptDrdPoA+oC+FgZEiiBo2YY3TY5a49bLTYOcJhLw7L45HaBXyMnx0hA7byPW1OPZOU4fMIuby8wVEGDUOpldiQvgCGZiQje31DMA48x46w6xxOHzJTw7Pa+Nzc+6eK3ZQhuHbBbJ6RlOQKu0KeD3y0NgH3ysWtOjtTrUHp0fph+VRtb4uNbY7BQiuCIlvOeDKJK0ufwk3YuBm2kI7FyV2XsQjNBscWywaiafhkLrUZoACgASkCACqngCaAH1GECADIAQWvAEouFWN-QuzGeAStjcAsPE5clOVdC4OStFMXFzC4Thcc5k2CfIVz9IhMAACzATARHkGpw0mANoWDOFVww7DcPwxRyKmGE5kxRZf21UBNjzWCUPHMd808NxAitBwHgNOczk8YJWSuQJLjQiNCCwnC8OqGj0M4b9G0lFtpU9fh5KopTxlolUGPVJZTGjFi7EcIDgm8QhMkZQ5WRZJ0sziKyUhJU5OXEktEOk4o+SrTCyFgQjymIoNhBDbTCGC0L5SMtUsVMlZzJ7VjCS+ZkSzCD59UyIsXAE8SdmSFw8hyIs0leHYZMmWKQrC-01KbKU2xlFSGviwz6KSpjNTS2MMq2TIDT8UtAlyFMnTSK1ggTQcE1LXxENCd0ApiuKmsaB8ADUAEkcCBW8cAAeQfW8gQADQABX289GGY9LLOtdI7KdftbW8XjXNuPIPHyTxeJWpczjcIoAp4dh4GWGLKmogyVKeoaXu2M0SUm05ypeFIxIE11Hh2VI+Mm9wwj0CsNqrbTFRqZH-17MJkxJTN-HTXIGRTaC9Fg9MUjyD4HKXQoqdo6ta1o+mAIcJDBzNbH8mpFC3Gggs4JSU1gc5K46v+NSpd7QTMiZdMUKpPw3UCVk5qSJwlsmnx0gLUJdbXXTFLGbbIwG7sUd1blSupPZEncQ5bSKtzAKnTzRITIC0gCQJXaILbOx9v9pdCUrgb0U03Aicq8l+wlbJEs0lq88JwmTsiVNPMAoHQHdmkgA3hrHAc8xZPiUgTdJipLA5yqB1kshOHXRaR9OLN1IClzsosrbzJ0nOLrYjVgomHgyTkfCks4IYKIA */
  createMachine<DeviceCodeContext>(
    {
      context: {
        retryCount: null,
        nextRetryTime: null,
        deviceCodeInfo: null,
        authorizationResponse: null,
      },
      id: 'deviceCode',
      initial: 'loadStoredDeviceCode',
      states: {
        loadStoredDeviceCode: {
          invoke: {
            src: 'getStoredDeviceCodeInfo',
            onDone: [
              {
                actions: assign({
                  deviceCodeInfo: (
                    _,
                    event: DoneInvokeEvent<DeviceCodeInfoResponse>
                  ) => event.data,
                }),
                target: 'checkStoredDeviceCode',
              },
            ],
            onError: [
              {
                target: 'getDeviceCode',
              },
            ],
          },
        },
        deviceCodeExpired: {
          entry: assign({ deviceCodeInfo: null }),
          invoke: {
            src: 'deleteDeviceCodeInfo',
            onDone: [
              {
                target: 'getDeviceCode',
              },
            ],
            onError: [
              {
                target: 'getDeviceCode',
              },
            ],
          },
        },
        getDeviceCode: {
          invoke: {
            src: 'getDeviceCode',
            id: 'getDeviceCode',
            onDone: [
              {
                actions: assign<
                  DeviceCodeContext,
                  DoneInvokeEvent<DeviceCodeInfoResponse>
                >({
                  retryCount: null,
                  deviceCodeInfo: (
                    _: DeviceCodeContext,
                    event: DoneInvokeEvent<DeviceCodeInfoResponse>
                  ) => event.data,
                }),
                target: 'hasDeviceCode',
              },
            ],
            onError: [
              {
                target: 'error',
              },
            ],
          },
        },
        error: {
          entry: assign<DeviceCodeContext>({
            deviceCodeInfo: null,
            retryCount: (context: DeviceCodeContext) =>
              context.retryCount ?? 0 + 1,
            nextRetryTime: (context: DeviceCodeContext) =>
              new Date().getTime() +
              ((context.retryCount ?? 0) === 0
                ? 0
                : Math.pow(2, context.retryCount ?? 0 - 1) * BASE_RETRY_DELAY),
          }),
          exit: assign({
            nextRetryTime: null,
          }),
          after: {
            RETRY_DELAY: {
              target: 'getDeviceCode',
            },
          },
        },
        checkStoredDeviceCode: {
          invoke: {
            src: 'checkDeviceCode',
            onDone: [
              {
                actions: assign({
                  authorizationResponse: (
                    _,
                    event: DoneInvokeEvent<AuthorizationResponse>
                  ) => event.data,
                }),
                cond: (
                  _,
                  event: DoneInvokeEvent<AuthorizationResponse | null>
                ) => event.data !== null,
                target: 'deviceCodeRegistered',
              },
              {
                target: 'hasDeviceCode',
              },
            ],
            onError: [
              {
                target: 'error',
              },
            ],
          },
        },
        hasDeviceCode: {
          entry: send(
            { type: 'DEVICE_CODE_EXPIRED' },
            {
              id: 'deviceCodeExpiration',
              delay: context =>
                context.deviceCodeInfo
                  ? context.deviceCodeInfo.expirationTime - new Date().getTime()
                  : 5000,
            }
          ),
          exit: cancel('deviceCodeExpiration'),
          invoke: {
            src: 'handleDeviceCode',
            onDone: [
              {
                actions: assign({
                  authorizationResponse: (
                    _,
                    event: DoneInvokeEvent<AuthorizationResponse>
                  ) => event.data,
                }),
                target: 'deviceCodeRegistered',
              },
            ],
            onError: [
              {
                target: 'error',
              },
            ],
          },
          on: {
            DEVICE_CODE_EXPIRED: {
              target: 'deviceCodeExpired',
            },
          },
        },
        deviceCodeRegistered: {
          invoke: {
            src: 'deleteDeviceCodeInfo',
          },
          type: 'final',
        },
      },
    },
    {
      delays: {
        RETRY_DELAY: context => {
          if (!context.nextRetryTime) {
            return DEFAULT_RETRY_DELAY
          }

          return context.nextRetryTime - new Date().getTime()
        },
      },
      services: {
        getDeviceCode: async () => {
          const client = await Client.get()
          const deviceCodeInfo = await client.getDeviceCode()
          if (!deviceCodeInfo) {
            throw new Error('Could not get device code info')
          }

          return deviceCodeInfo
        },
        checkDeviceCode: async context => {
          const { deviceCodeInfo } = context
          if (!deviceCodeInfo) {
            throw new Error('Invalid state, there is no device code info')
          }
          const { deviceCode } = deviceCodeInfo

          const client = await Client.get()

          const authorizationResponse = await client.checkDeviceCode(deviceCode)

          if (authorizationResponse) {
            return authorizationResponse
          }

          return null
        },
        handleDeviceCode: async context => {
          if (!context.deviceCodeInfo) {
            throw new Error('Invalid state, there is no device code info')
          }

          await saveDeviceCodeInfo(context.deviceCodeInfo)

          const { deviceCode } = context.deviceCodeInfo

          const client = await Client.get()

          const authorizationResponse = await client.waitDeviceRegistered(
            deviceCode
          )

          if (!authorizationResponse) {
            throw new Error('Could not get authorization response')
          }

          return authorizationResponse
        },
        getStoredDeviceCodeInfo: async () => {
          const deviceCodeInfo = await getDeviceCodeInfo()
          if (!deviceCodeInfo) {
            throw new Error('Could not get stored device code info')
          }

          return deviceCodeInfo
        },
        deleteDeviceCodeInfo: async () => {
          await deleteDeviceCodeInfo()
        },
      },
    }
  )
