import { useTranslation } from 'react-i18next'
import { AuthorizationResponse } from 'src/types'
import { ActivationCode } from './ActivationCode'
import { Error } from './Error'
import { Header } from './Header'
import { useDeviceCode } from './device-code/useDeviceCode'

interface PairingProps {
  onAuthorizationResponse: (
    authorizationResponse: AuthorizationResponse
  ) => void
}

export function Pairing({ onAuthorizationResponse }: PairingProps) {
  const { t } = useTranslation()
  const state = useDeviceCode(onAuthorizationResponse)

  const { isLoading, nextRetryTime } = state

  if (isLoading) {
    return <Header title={t('device.authenticating')} />
  }

  if (nextRetryTime !== null) {
    return (
      <>
        <Header title={t('device.failedDeviceCode')} />
        <Error nextRetryTime={nextRetryTime} />
      </>
    )
  }

  const { deviceCode } = state

  return (
    <>
      <Header title={t('device.pairDescription')} />
      <ActivationCode userCode={deviceCode} />
    </>
  )
}
