import React from 'react'
import styled from 'styled-components'

interface ImageProps {
  contain: boolean
  src: string
}

interface SlideshowImageProps {
  contain?: boolean
  src: string | null
}

const Image = styled.img<ImageProps>`
  object-fit: ${props => (props.contain ? 'contain' : 'cover')};
  width: 100%;
  height: 100%;
`

const Placeholder = styled.div`
  background-color: #555;
  width: 100%;
  height: 100%;
`

export function SlideshowImage({ src, contain = false }: SlideshowImageProps) {
  return src !== null ? <Image src={src} contain={contain} /> : <Placeholder />
}
