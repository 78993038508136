import { DoneInvokeEvent, assign, createMachine } from 'xstate'
import { refreshToken } from 'src/service/device-auth'
import { AuthorizationResponse } from 'src/types'
import {
  deleteAuthorizationResponse,
  getAuthorizationResponse,
  saveAuthorizationResponse,
} from './persistence'

type AuthenticationContext = {
  authorizationResponse: AuthorizationResponse | null
}

interface OnAuthenticationResponse {
  type: 'onAuthorizationResponse'
  authorizationResponse: AuthorizationResponse
}

interface OnUnauthenticated {
  type: 'onUnauthenticated'
}

type AuthenticationEvents = OnAuthenticationResponse | OnUnauthenticated

const DEFAULT_ACCESS_TOKEN_EXPIRATION_DELAY = 60 * 60 * 1000
const DEFAULT_RETRY_REFRESH_TOKEN_DELAY = 60 * 1000 // 1 minute
const MAX_RETRY_TIME_MS = 24 * 60 * 60 * 1000 // 24 hour

function hasAuthorizationResponse({
  authorizationResponse,
}: AuthenticationContext) {
  return authorizationResponse !== null
}

function isRetryRefreshTokenTimedout({
  authorizationResponse,
}: AuthenticationContext) {
  return (
    authorizationResponse !== null &&
    MAX_RETRY_TIME_MS <
      new Date().getTime() - authorizationResponse.expirationTime
  )
}

function hasNewAuthorizationResponse(
  _: AuthenticationContext,
  { data }: DoneInvokeEvent<AuthorizationResponse>
) {
  return data !== null
}

export const autheticationMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFmdBLAxsngPYB2AssvprqWAHSzrIBO6AxANoAMAuoqAAOxWLhKkBIAB6IAtABYA7IvoBOAEwA2JevlLVmgKwBmADQgAnnPUAOFboCM3RSc235xxcYC+382iwcAiJcMkpqWgZqMHwAawBBDExiFlwALxCyACU4YVJYMC4+SWFRcUkZBFlDTW56TRsbXWNnQ3UDG3MrKps6hyaPA3l1Q2VNHz8QAOw8QnFwmjp6aLjErBT0zNIc2DyCrgd+JBBSsVCJY8rqhwd6XsNhr2HDVVUarrkb+pN2huM7RTqYyqGy+fxJIJzc4LSL0VCkaZgUizIiQdhkNbJVIZcQ7PZgHhHIQiM5kCpyV7yejyIy6TQOBpKGyGD4IZn1QG6B4gzQufpgqYQlHzKiLBiI5HBdBosgAVQREMlc0ghJKJPKl0QWhs9EU3CUDjeJn18lUrNkTWpxjpNgMDMUmiMAsRwuhothEuFaKkTFR9GQADNpSwABTxADC4YAogBlGMAfQAKgB5ADSUYAcvGowANAAKAEksvFEwXk1mACJRgAy8QAmgBKdguqVuiJLT1SlXFY6nDWgSq3R0PIH-VQOYzyEwOeTmhzqancYzGGfqbjj00TxTOoWtsLupYsMABo+wTCJ4ixJHsCBkBi0ABul-Fu6h+-bDCPJ7g5+fpAQj7EG+pCEqqvbquc5JVECtwaDc8jcEuzJtC4rKAvQvzyA4DoriYJigpMLbATCh7Hqev5XqQN53vQgFXv6r5bCRn5kT+F6UQBpBPsBoGHGqZSQZq0FTmobzAtwQJ2IY3DaKy7Q6iCLSaG8zi8vqhg7oErrvmK9BfuR7HXmALAsCk9CCAANkQAYpAAtgxWl7hQB4sd+Z6Gf+gE8XwYHEgJZJCbyOraA4LybjYprrqyTg6oopptNJ9xYao8iaTMTnMXpOAsBYORuRR14+sw0r+kGxkhlkUaJlkdbxpVABilUxgAEkmaaZvGVa1o2zaMSKH5ZegOV5QZf6+ScEEBQOiBOKoGHcDY4yKK8E4eOoiislOhjfPOqj-MMGjMmlkJMS5g3Dax7l-uwRV+oGwYVVVNV1VGjWxq1KbppWNb1k2RGnQNR5Dbll0FSBfHgf5FzTQg9r0CuvShW0NgruOrLLnUwy2AMtoNOo6i+JMpDEBAcCSP9-VivxpLQ9Inz6guNJtDSDIRYoi3mvItpqGz2i6Cldgycd2nOQNvpsNT-Z01UhjzvD2gSdwM5ONhs6WHICGM2zeqjClQWaMLGVnSsCRJBsOLnHiZAFJLgkw9UvL0Ou3CGPh2i8mj6vQeu1IaAlC3xeuBuEX1ba6fCnbKhAttTdL1SLfUMnqPOXgaA6nRe1odSKUu61bsYToh45xFnZHqLR5DNNQbIqv1NaC39HBLxNOalprTSuP2sOhsl4DoMeTHtOVFosGJSM1pGMCDisijurxetWhKMtqVF+lve6UDF35QPldS5UvKaE7+q2i0GiOGaXtTlSoXzi8TjJy4QuE0AA */
  createMachine<AuthenticationContext, AuthenticationEvents>(
    {
      context: { authorizationResponse: null },
      id: 'autheticationMachine',
      initial: 'start',
      states: {
        start: {
          entry: assign({
            authorizationResponse: () => getAuthorizationResponse(),
          }),
          always: [
            {
              target: 'checkAuthorizationResponse',
            },
          ],
        },
        checkAuthorizationResponse: {
          always: [
            {
              cond: 'hasAuthorizationResponse',
              target: 'authenticated',
            },
            {
              target: 'unauthenticated',
            },
          ],
        },
        unauthenticated: {
          entry: [
            'deleteAuthorizationResponse',
            assign({
              authorizationResponse: null,
            }),
          ],
          on: {
            onAuthorizationResponse: {
              actions: [
                assign({
                  authorizationResponse: (_, { authorizationResponse }) =>
                    authorizationResponse,
                }),
                'saveAuthorizationResponse',
              ],
              target: 'checkAuthorizationResponse',
            },
          },
        },
        authenticated: {
          after: {
            ACCESS_TOKEN_EXPIRATION_DELAY: {
              target: 'refreshToken',
            },
          },
          on: {
            onUnauthenticated: {
              target: 'refreshToken',
            },
          },
        },
        refreshToken: {
          invoke: {
            src: 'refreshToken',
            onDone: [
              {
                cond: hasNewAuthorizationResponse,
                actions: [
                  assign({
                    authorizationResponse: (_, event) => event.data,
                  }),
                  'saveAuthorizationResponse',
                ],
                target: 'authenticated',
              },
              { target: 'unauthenticated' },
            ],
            onError: [
              {
                target: 'retryRefreshToken',
              },
            ],
          },
        },
        retryRefreshToken: {
          after: {
            RETRY_REFRESH_TOKEN_DELAY: [
              {
                cond: 'isRetryRefreshTokenTimedout',
                target: 'unauthenticated',
              },
              {
                target: 'refreshToken',
              },
            ],
          },
        },
      },
    },
    {
      guards: {
        hasAuthorizationResponse,
        isRetryRefreshTokenTimedout,
      },
      delays: {
        ACCESS_TOKEN_EXPIRATION_DELAY: context => {
          const { authorizationResponse } = context
          if (!authorizationResponse) {
            return DEFAULT_ACCESS_TOKEN_EXPIRATION_DELAY
          }

          return authorizationResponse.expirationTime - new Date().getTime()
        },
        RETRY_REFRESH_TOKEN_DELAY: _ => {
          return DEFAULT_RETRY_REFRESH_TOKEN_DELAY
        },
      },
      actions: {
        saveAuthorizationResponse: ({ authorizationResponse }) => {
          if (authorizationResponse) {
            saveAuthorizationResponse(authorizationResponse)
          }
        },
        deleteAuthorizationResponse: () => {
          deleteAuthorizationResponse()
        },
      },
      services: {
        refreshToken: async context => {
          const { authorizationResponse } = context
          if (!authorizationResponse) {
            throw new Error('Invalid state, there is no authorization response')
          }

          return refreshToken(authorizationResponse.refreshToken)
        },
      },
    }
  )
