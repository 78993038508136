import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Countdown } from '../components/Countdown'

const ErrorWrapper = styled.div`
  padding: 4rem;
  text-align: center;
`

const RetryMessage = styled.div`
  font-size: 2.5rem;
`

interface ErrorProps {
  nextRetryTime: number | null
}

export function Error({ nextRetryTime }: ErrorProps) {
  const { t } = useTranslation()
  return (
    <>
      <ErrorWrapper>
        <RetryMessage>
          <Countdown
            time={nextRetryTime ?? 0}
            render={(seconds: number) =>
              t('device.retryText', { count: seconds })
            }
          />
        </RetryMessage>
      </ErrorWrapper>
    </>
  )
}
