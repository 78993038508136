import { useEffect } from 'react'
import { useMachine } from '@xstate/react'
import { AuthorizationResponse } from 'src/types'
import { deviceCodeMachine } from './deviceCodeMachine'

type Loading = {
  isLoading: true
  nextRetryTime: null
  deviceCode: null
}

type HasDeviceCode = {
  isLoading: false
  nextRetryTime: null
  deviceCode: string
}

type Retry = {
  isLoading: false
  nextRetryTime: number
  deviceCode: null
}

type UseDeviceCodeReturn = Loading | HasDeviceCode | Retry

export function useDeviceCode(
  onAuthorizationResponse: (
    authorizationResponse: AuthorizationResponse
  ) => void
): UseDeviceCodeReturn {
  const [state] = useMachine(deviceCodeMachine)

  const { deviceCodeInfo, nextRetryTime, authorizationResponse } = state.context

  useEffect(() => {
    if (authorizationResponse) {
      onAuthorizationResponse(authorizationResponse)
    }
  }, [onAuthorizationResponse, authorizationResponse])

  const initializing =
    state.matches('loadStoredDeviceCode') ||
    state.matches('checkStoredDeviceCode')

  if (deviceCodeInfo && !initializing) {
    return {
      isLoading: false,
      nextRetryTime: null,
      deviceCode: deviceCodeInfo.userCode,
    }
  }

  if (nextRetryTime !== null) {
    return {
      isLoading: false,
      nextRetryTime,
      deviceCode: null,
    }
  }

  return {
    isLoading: true,
    nextRetryTime,
    deviceCode: null,
  }
}
