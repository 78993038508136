interface Config {
  environment: string
  region: string
  deviceAuthServer: string
  graphQLEndpoint: string
  apiKey: string
  sentryDsn: string
}

let config: Config

export async function getConfig() {
  if (!config) {
    const configResponse = await fetch('/config.json', {
      cache: 'no-cache',
    })
    config = await configResponse.json()
  }
  return config
}
