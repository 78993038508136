import { useRef } from 'react'
import { handleException } from '../handleException'

export function useFullscreen() {
  const fullscreenElementRef = useRef<HTMLDivElement>(null)

  const switchFullscreen = async () => {
    try {
      if (document.fullscreenElement) {
        await document.exitFullscreen()
      } else {
        if (fullscreenElementRef.current) {
          await fullscreenElementRef.current.requestFullscreen()
        }
      }
    } catch (e) {
      handleException(e)
    }
  }

  return {
    ref: fullscreenElementRef,
    switchFullscreen,
  }
}
