import React from 'react'
import { useRemainingSeconds } from './useRemainingSeconds'

interface CountdownProps {
  time: number
  render: (seconds: number) => string
}

export function Countdown({ time, render }: CountdownProps) {
  const remainingSeconds = useRemainingSeconds(time)
  return remainingSeconds !== null ? <>{render(remainingSeconds)}</> : null
}
