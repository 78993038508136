import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useFullscreen } from './useFullscreen'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

interface FullscreenProps {
  children: ReactNode
}

export function Fullscreen({ children }: FullscreenProps) {
  const { ref, switchFullscreen } = useFullscreen()
  return (
    <Wrapper ref={ref} onClick={switchFullscreen}>
      {children}
    </Wrapper>
  )
}
