import React, { ReactElement } from 'react'
import styled from 'styled-components'
import logo from './logo-landscape.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledImage = styled.img`
  height: 4rem;
`

interface Props {
  className?: string
}

export function Logo({ className }: Props): ReactElement {
  return (
    <Wrapper className={className}>
      <StyledImage src={logo} alt="Avica" />
    </Wrapper>
  )
}
