import PersistenceService from 'src/service/persistence'
import { AuthorizationResponse } from 'src/types'

const persistenceService = new PersistenceService()

const STORE_KEY = 'authorizationResponse'

export function getAuthorizationResponse(): AuthorizationResponse | null {
  return persistenceService.get(STORE_KEY)
}

export function saveAuthorizationResponse(
  authorizationResponse: AuthorizationResponse
) {
  persistenceService.save(STORE_KEY, authorizationResponse)
}

export function deleteAuthorizationResponse() {
  persistenceService.delete(STORE_KEY)
}
