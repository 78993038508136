import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AuthContext } from './AuthContext'
import { Header } from './Header'
import { Pairing } from './Pairing'
import { useDeviceAuth } from './useDeviceAuth'

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.lightBlue};
  border-radius: 4rem;
  padding: 4rem;
  width: 100%;
`

const InnerWrapper = styled.div`
  max-width: 80rem;
`
interface CodeAuthProps {
  children: ReactNode
}

export function CodeAuth({ children }: CodeAuthProps) {
  const {
    isAuthenticating,
    accessToken,
    onAuthorizationResponse,
    onUnauthenticated,
  } = useDeviceAuth()

  const { t } = useTranslation()

  if (isAuthenticating) {
    return (
      <Container>
        <Wrapper>
          <InnerWrapper>
            <Header title={t('device.authenticating')} />
          </InnerWrapper>
        </Wrapper>
      </Container>
    )
  }

  return accessToken !== null ? (
    <AuthContext.Provider
      value={{
        accessToken,
        onUnauthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <Pairing onAuthorizationResponse={onAuthorizationResponse} />
        </InnerWrapper>
      </Wrapper>
    </Container>
  )
}
