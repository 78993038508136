import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Logo } from 'src/components/logo/Logo'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
`

const StyledLogo = styled(Logo)`
  margin-bottom: 4rem;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  margin-bottom: 4rem;
  text-transform: uppercase;
  text-align: center;
`

interface HeaderProps {
  title: string
}

export function Header({ title }: HeaderProps): ReactElement {
  return (
    <Wrapper>
      <StyledLogo />
      <Title>{title}</Title>
    </Wrapper>
  )
}
