import { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import { AppContainer } from './AppContainer'
import { GlobalStyleHelper as GlobalStyle } from './GlobalStyle'
import { theme } from './theme'

export function App() {
  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppContainer />
      </ThemeProvider>
    </Suspense>
  )
}
