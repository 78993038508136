import { useContext } from 'react'
import { AuthContext } from './AuthContext'

export function useAuth() {
  const { accessToken, onUnauthenticated } = useContext(AuthContext)

  return {
    accessToken,
    onUnauthenticated,
  }
}
