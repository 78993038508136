import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { getConfig } from 'src/config'

export async function createApolloClient(accessToken?: string) {
  const config = await getConfig()

  const url = config.graphQLEndpoint
  const region = config.region
  const auth: AuthOptions = accessToken
    ? {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: accessToken,
      }
    : {
        type: AUTH_TYPE.API_KEY,
        apiKey: config.apiKey,
      }

  const httpLink = new HttpLink({ uri: url })

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ])

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}
