import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { Slide } from '../types'
import { SlideshowImage } from './SlideshowImage'
import { useSlideshowMachine } from './useSlideshowMachine'

const TRANSITION_MS = 2000

interface SlideAnimatorProps {
  ms: number
}

const SlideAnimator = styled.div<SlideAnimatorProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //transform: translateX(-100%);
  opacity: 0;

  &.appear {
    opacity: 0;
    //transform: translateX(-100%);
  }

  &.appear-active {
    opacity: 1;
    //transform: translateX(0%);
    transition: all ${props => props.ms}ms ease-in-out;
  }

  &.appear-done {
    opacity: 1;
    //transform: translateX(0%);
  }

  &.enter {
    opacity: 0;
    //transform: translateX(-100%);
  }

  &.enter-active {
    opacity: 1;
    //transform: translateX(0%);
    transition: all ${props => props.ms}ms ease-in-out;
  }

  &.enter-done {
    opacity: 1;
    //transform: translateX(0%);
  }

  &.exit {
    opacity: 1;
    //transform: translateX(0%);
  }

  &.exit-active {
    opacity: 0;
    //transform: translateX(100%);
    transition: all ${props => props.ms}ms ease-in-out;
  }

  &.exit-done {
    opacity: 0;
    //transform: translateX(100%);
  }
`

interface SlidesProps {
  slides: Slide[]
  slideDurationMs: number
}

export function Slides({ slides, slideDurationMs }: SlidesProps) {
  const currentIndex = useSlideshowMachine(slides.length, slideDurationMs)

  return (
    <>
      {slides.map((slide, index) => (
        <CSSTransition
          key={index}
          in={index === currentIndex}
          appear
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false)
          }}
        >
          <SlideAnimator ms={TRANSITION_MS}>
            <SlideshowImage src={slide.media ? slide.media.url : null} />
          </SlideAnimator>
        </CSSTransition>
      ))}
    </>
  )
}
