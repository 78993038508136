import { CodeAuth } from './code-auth/CodeAuth'
import { useScreen } from './hooks/useScreen'
import { Slideshow } from './slideshow/Slideshow'

function SlideshowContent() {
  const { screenId, slides, slideDurationMs } = useScreen()

  return (
    <Slideshow
      slides={slides}
      screenId={screenId}
      slideDurationMs={slideDurationMs}
    />
  )
}

export function AuthenticatedSlideshow() {
  return (
    <CodeAuth>
      <SlideshowContent />
    </CodeAuth>
  )
}
