import EventEmitter from 'events'
import {
  ApolloClient,
  NormalizedCacheObject,
  gql,
  isApolloError,
} from '@apollo/client'
import { setTag } from '@sentry/browser'
import { Subscription } from 'zen-observable-ts'
import { handleException } from '../../handleException'
import { ScreenContent } from '../../types'
import { createApolloClient } from '../apollo-client'
import PersistenceService from '../persistence'

const getScreen = gql`
  query screen {
    screenContent {
      screenId
      slideDurationMs
      slides {
        media {
          url
        }
      }
    }
  }
`

const subscribeOnScreenContent = gql`
  subscription onScreenContent($screenId: ID!) {
    onScreenContent(screenId: $screenId) {
      screenId
    }
  }
`

export class ScreenService extends EventEmitter {
  persistence: PersistenceService
  client: ApolloClient<NormalizedCacheObject> | null = null
  subscription: Subscription | null = null
  screenId: string | null = null

  constructor() {
    super()
    this.persistence = new PersistenceService()
    this.loadScreenFromCache()
  }

  async setAccessToken(accessToken: string | null) {
    this.unsubscribe()

    if (!accessToken) {
      this.client = null
      return
    }
    this.client = await createApolloClient(accessToken)
    this.reloadScreen()
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  subscribe() {
    this.unsubscribe()
    if (!this.client) {
      return
    }
    this.subscription = this.client
      .subscribe({
        query: subscribeOnScreenContent,
        variables: {
          screenId: this.screenId,
        },
      })
      .subscribe({
        next: async () => {
          await this.reloadScreen()
        },
        error: error => {
          console.warn(error)
        },
      })
  }

  async cacheScreenContent(screenContent: ScreenContent) {
    return this.persistence.save('screenContent', screenContent)
  }

  async loadScreenFromCache() {
    const screenContent = await this.persistence.get('screenContent')
    if (screenContent) {
      await this.updateScreen(screenContent)
    }
  }

  async preloadImage(url: string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = url
      img.onload = resolve
      img.onerror = () => {
        reject(new Error(`Failed to preload image: ${url}`))
      }
    })
  }

  async preloadSlides(screenContent: ScreenContent) {
    if (screenContent && screenContent.slides) {
      await Promise.all(
        screenContent.slides.map(
          slide =>
            slide &&
            slide.media &&
            slide.media.url &&
            this.preloadImage(slide.media.url)
        )
      )
    }
  }

  async updateScreen(screenContent: ScreenContent) {
    await this.preloadSlides(screenContent)
    this.emit('screenContent', screenContent)
  }

  async reloadScreen() {
    if (!this.client) {
      return
    }
    try {
      const {
        data: { screenContent },
      } = await this.client.query<{ screenContent: ScreenContent }>({
        query: getScreen,
        fetchPolicy: 'no-cache',
      })
      if (this.screenId !== screenContent.screenId) {
        this.screenId = screenContent.screenId
        setTag('screenId', this.screenId)
        this.subscribe()
      }
      await this.cacheScreenContent(screenContent)
      await this.updateScreen(screenContent)
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) {
        if (e.networkError && 'statusCode' in e.networkError) {
          if (e.networkError.statusCode === 401) {
            await this.persistence.delete('screenContent')
            this.emit('unauthenticated')
            return
          }
        }
      }
      handleException(e)
    }
  }
}

export const screenService = new ScreenService()
