import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { AuthenticatedSlideshow } from './AuthenticatedSlideshow'
import { DemoSlideshow } from './DemoSlideshow'
import { Fullscreen } from './components/Fullscreen'

const LandscapeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
`

const PortraitWrapper = styled.div`
  width: 100vh;
  height: 100vw;
  overflow: hidden;
  display: flex;
  position: relative;
  transform: rotate(90deg) translateY(-100vw);
  transform-origin: top left;
`

type Orientation = 'portrait' | 'landscape'

function getOrientation(): Orientation {
  if (window.location.search === '?portrait') {
    return 'portrait'
  }

  return 'landscape'
}

export function AppContainer() {
  const { t } = useTranslation()

  const Wrapper =
    getOrientation() === 'portrait' ? PortraitWrapper : LandscapeWrapper

  return (
    <Fullscreen>
      <Helmet>
        <title>Avica - {t('app.title')}</title>
      </Helmet>
      <Wrapper>
        <HashRouter>
          <Switch>
            <Route exact path="/demo" component={DemoSlideshow} />
            <Route component={AuthenticatedSlideshow} />
          </Switch>
        </HashRouter>
      </Wrapper>
    </Fullscreen>
  )
}
