import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Slide } from 'src/types'
import { Slides } from './Slides'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
`

const Loader = styled.h3`
  text-transform: uppercase;
  font-size: 5vw;
  margin: 0;
  color: #aaa;
`

interface SlideshowProps {
  slides: Slide[] | null
  screenId: string | null
  slideDurationMs: number
}

export function Slideshow({
  screenId,
  slides,
  slideDurationMs,
  ...props
}: SlideshowProps) {
  const { t } = useTranslation()

  return (
    <Wrapper {...props}>
      {screenId ? (
        slides ? (
          <Slides slides={slides} slideDurationMs={slideDurationMs} />
        ) : (
          <Loader>{t('slideshow.empty')}</Loader>
        )
      ) : (
        <Loader>{t('slideshow.loading')}</Loader>
      )}
    </Wrapper>
  )
}
