import { actions, createMachine, EventObject } from 'xstate'

const { assign, cancel, send } = actions

interface UpdateSlideshowEvent extends EventObject {
  type: 'updateSlideshow'
  slideMilliseconds: number
  slideshowLength: number
}

interface NextSlideEvent extends EventObject {
  type: 'nextSlide'
}

type Events = UpdateSlideshowEvent | NextSlideEvent

interface Context {
  currentIndex: number
  slideshowLength: number
  slideStartedAt: number
  slideMilliseconds: number
}

const DEFAULT_SLIDESHOW_MS = 30000

const saveSlideshow = assign(
  (
    _: Context,
    { slideshowLength, slideMilliseconds }: UpdateSlideshowEvent
  ) => ({
    slideshowLength,
    slideMilliseconds,
  })
)

const scheduleNextSlide = send<Context, UpdateSlideshowEvent>('nextSlide', {
  id: 'slideTimer',
  delay: ({ slideStartedAt, slideMilliseconds }: Context) =>
    slideStartedAt + slideMilliseconds - new Date().getTime(),
})

const cancelNextSlide = cancel('slideTimer')

const isSlideshowShrinked = (
  { currentIndex }: Context,
  event: UpdateSlideshowEvent
) => currentIndex >= event.slideshowLength

const isSlideshowTimingChanged = (
  context: Context,
  event: UpdateSlideshowEvent
) => event.slideMilliseconds !== context.slideMilliseconds

export const slideshowMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5SwDYEsJwBYHsDuAdBimAMQCuADhAIYAuYAyuprLnoqJTrGnWjgB2nEAA9EARgDMAdgJSArAE4FMlQCYlStQqkAaEAE9EANgAcBAAzqTMk+YAsUqSYnqHAXw8HUGbPgIAJ3JBQTRBKCIAWxoYCmp6JhZ-DiQQbl5+IRFxBHMpAiVFJQcFdTNbKSUTA2MEAFo7QpkpdxsZSwU3Tq8fZLYA4NDwyLQYuMEwUTpmPxEMvgFhNNyHJrMld3yHFSqJWsR1TQIy2wdznYlbdS9vEEEcVhFfVnYiCBJ5nkXslcR6tzqAg2KqWaQyMwbcxKA4IMwSKztBzlPbqGRuXogF4pIIhMIRaKxMBfTJLHKINYFWT5EyWKQSCQyMqwqqFCSWGQOExrWmuZyY7EDQhDfFQEk-ZagVY7eR2MwuOkMpnqWEAmXaVpVUqWJwSJQC-rscVZSVif4SLnAkyg8GQ6owoz-I5AjXqFxuuldGQ3W5AA */
  createMachine<Context, Events>(
    {
      context: {
        currentIndex: 0,
        slideshowLength: 0,
        slideStartedAt: 0,
        slideMilliseconds: DEFAULT_SLIDESHOW_MS,
      },
      id: 'slideshow',
      initial: 'idle',
      states: {
        idle: {
          on: {
            updateSlideshow: {
              actions: assign((_, { slideshowLength, slideMilliseconds }) => ({
                currentIndex: 0,
                slideshowLength,
                slideMilliseconds,
              })),
              target: 'running',
            },
          },
        },
        running: {
          initial: 'image',
          states: {
            image: {
              entry: [
                assign({ slideStartedAt: () => new Date().getTime() }),
                scheduleNextSlide,
              ],
              on: {
                updateSlideshow: [
                  {
                    actions: [saveSlideshow, send('nextSlide')],
                    cond: isSlideshowShrinked,
                  },
                  {
                    actions: [
                      saveSlideshow,
                      cancelNextSlide,
                      scheduleNextSlide,
                    ],
                    cond: isSlideshowTimingChanged,
                  },
                  {
                    actions: saveSlideshow,
                  },
                ],
                nextSlide: {
                  actions: 'stepToNextSlide',
                  target: 'image',
                  internal: false,
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        stepToNextSlide: assign(({ currentIndex, slideshowLength }) => ({
          currentIndex:
            slideshowLength === 0 ? 0 : (currentIndex + 1) % slideshowLength,
        })),
      },
    }
  )
