import { handleException } from '../../handleException'

class PersistenceService {
  save(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  delete(key: string) {
    localStorage.removeItem(key)
  }

  get(key: string) {
    try {
      const item = localStorage.getItem(key)
      if (item) {
        return JSON.parse(item)
      }
    } catch (e) {
      handleException(e)
      this.delete(key)
    }

    return null
  }
}

export default PersistenceService
