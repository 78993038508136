import { useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { useMachine } from '@xstate/react'
import { AuthorizationResponse } from 'src/types'
import { autheticationMachine } from './authentication/authenticationMachine'

export function useDeviceAuth() {
  const [state, send, interpreter] = useMachine(autheticationMachine)

  useEffect(() => {
    interpreter.onEvent(event => {
      Sentry.addBreadcrumb({
        category: 'authentication',
        level: Sentry.Severity.Info,
        message: `From state: ${interpreter.state.value}`,
        data: event,
      })
    })
    interpreter.onTransition((state, event) => {
      Sentry.addBreadcrumb({
        category: 'authentication',
        level: Sentry.Severity.Info,
        message: `To state: ${state.value}`,
        data: event,
      })
    })
  }, [interpreter])

  useEffect(() => {
    if (state.value === 'unauthenticated') {
      Sentry.captureException(new Error('Authentication failed'))
    }
  }, [state.value])

  const onUnauthenticated = useCallback(() => {
    send('onUnauthenticated')
  }, [send])

  const onAuthorizationResponse = useCallback(
    (authorizationResponse: AuthorizationResponse) => {
      send({
        type: 'onAuthorizationResponse',
        authorizationResponse,
      })
    },
    [send]
  )

  const { authorizationResponse } = state.context

  const accessToken = authorizationResponse?.accessToken ?? null

  const isAuthenticating = state.matches('start')

  return {
    isAuthenticating,
    accessToken,
    onAuthorizationResponse,
    onUnauthenticated,
  }
}
