import { useCallback, useEffect } from 'react'
import { useMachine } from '@xstate/react'
import { slideshowMachine } from './slideshowMachine'

export function useSlideshowMachine(slideshowLength: number, slideMs: number) {
  const [state, send] = useMachine(slideshowMachine)

  const updateSlideshow = useCallback(
    (slideshowLength: number, slideMs: number) => {
      send('updateSlideshow', {
        slideshowLength,
        slideMilliseconds: slideMs,
      })
    },
    [send]
  )

  useEffect(() => {
    updateSlideshow(slideshowLength, slideMs)
  }, [updateSlideshow, slideshowLength, slideMs])

  const { currentIndex } = state.context

  return currentIndex
}
