import { ReactElement } from 'react'
import { faPlug } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 2rem;
  padding: 3rem 4rem 6rem;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 4rem;
`

const IconWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.darkBlue};
  color: ${({ theme: { colors } }) => colors.primary};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
`

const CodeWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightBlue};
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CodeContainer = styled.div`
  display: flex;
  width: 100%;
`

const Code = styled.div`
  font-size: 6rem;
  font-weight: bold;
`

const Label = styled.div`
  color: ${({ theme: { colors } }) => colors.blue};
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
`
interface Props {
  userCode: string
}

function formatCode(code: string) {
  return code
    .split('')
    .map(
      (character, index) =>
        `${index % 3 === 0 && index !== 0 ? ' - ' : ''}${character}`
    )
    .join('')
}

export function ActivationCode({ userCode }: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Label>{t('device.activationCodeLabel')}:</Label>
      <CodeContainer>
        <IconWrapper>
          <Icon icon={faPlug} />
        </IconWrapper>
        <CodeWrapper>
          <Code>{userCode && formatCode(userCode)}</Code>
        </CodeWrapper>
      </CodeContainer>
    </Wrapper>
  )
}
