import EventEmitter from 'events'
import { gql } from '@apollo/client'
import axios, { AxiosError } from 'axios'
import { Subscription } from 'zen-observable-ts'
import { getConfig } from '../../config'
import {
  AuthorizationResponse,
  DeviceCodeInfoResponse,
  OpenIDConfiguration,
} from '../../types'
import { createApolloClient } from '../apollo-client'
import { getExpirationTime } from '../time'

const onDeviceRegisteredSubscription = gql`
  subscription onDeviceRegistered($deviceCode: String!) {
    onDeviceRegistered(deviceCode: $deviceCode) {
      deviceCode
    }
  }
`

let client: Client | null = null

export class Client extends EventEmitter {
  clientId: string
  issuer: OpenIDConfiguration
  deviceRegisteredSubscription: Subscription | null = null

  constructor(issuer: OpenIDConfiguration, clientId: string) {
    super()
    this.issuer = issuer
    this.clientId = clientId
  }

  private static async discover() {
    const config = await getConfig()
    const { data: openIDConfiguration } = await axios.get(
      `${config.deviceAuthServer}/.well-known/openid-configuration`
    )
    return new Client(openIDConfiguration, 'device')
  }

  static async get() {
    if (client) {
      return client
    }
    client = await Client.discover()
    return client
  }

  private isClientError(e: any) {
    return (
      e &&
      e.response &&
      e.response.status &&
      e.response.status >= 400 &&
      e.response.status < 500
    )
  }

  private isAuthorizationPending(
    e: AxiosError<Record<string, any> | undefined>
  ) {
    return e.response?.data?.error === 'authorization_pending'
  }

  async getDeviceCode(): Promise<DeviceCodeInfoResponse | null> {
    const params = new URLSearchParams()
    params.append('client_id', this.clientId)
    params.append('scope', 'offline_access')
    params.append('prompt', 'consent')

    try {
      const { data } = await axios.post(
        this.issuer.device_authorization_endpoint,
        params
      )

      return {
        deviceCode: data.device_code,
        userCode: data.user_code,
        verificationUri: data.verification_uri,
        verificationUriComplete: data.verification_uri_complete,
        expirationTime: getExpirationTime(data.expires_in),
      }
    } catch (e) {
      if (this.isClientError(e)) {
        return null
      }

      throw e
    }
  }

  async checkDeviceCode(
    deviceCode: string
  ): Promise<AuthorizationResponse | null> {
    const params = new URLSearchParams()
    params.append('client_id', this.clientId)
    params.append('grant_type', 'urn:ietf:params:oauth:grant-type:device_code')
    params.append('device_code', deviceCode)
    try {
      const { data } = await axios.post(this.issuer.token_endpoint, params)
      const { access_token, expires_in, refresh_token } = data
      return {
        accessToken: access_token,
        refreshToken: refresh_token,
        expirationTime: getExpirationTime(expires_in),
      }
    } catch (e) {
      if (this.isAuthorizationPending(e as any)) {
        return null
      }

      throw e
    }
  }

  async refreshToken(
    refreshToken: string
  ): Promise<AuthorizationResponse | null> {
    const params = new URLSearchParams()
    params.append('client_id', this.clientId)
    params.append('grant_type', 'refresh_token')
    params.append('refresh_token', refreshToken)

    try {
      const { data } = await axios.post(this.issuer.token_endpoint, params)
      const { access_token, expires_in, refresh_token } = data
      return {
        accessToken: access_token,
        refreshToken: refresh_token,
        expirationTime: getExpirationTime(expires_in),
      }
    } catch (e) {
      if (this.isClientError(e)) {
        return null
      }

      throw e
    }
  }

  async waitDeviceRegistered(deviceCode: string) {
    const client = await createApolloClient()

    return new Promise<AuthorizationResponse | null>((resolve, reject) => {
      try {
        this.deviceRegisteredSubscription?.unsubscribe()
        this.deviceRegisteredSubscription = client
          .subscribe({
            query: onDeviceRegisteredSubscription,
            variables: {
              deviceCode,
            },
          })
          .subscribe({
            next: async () => {
              this.deviceRegisteredSubscription?.unsubscribe()
              try {
                const authorizationResponse = await this.checkDeviceCode(
                  deviceCode
                )
                if (authorizationResponse) {
                  this.emit('deviceRegistered', authorizationResponse)
                }
                resolve(authorizationResponse)
              } catch (e) {
                reject(e)
              }
            },
            error: error => {
              this.deviceRegisteredSubscription?.unsubscribe()
              reject(error)
              console.warn(error)
            },
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
