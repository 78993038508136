import './global'
import 'react-app-polyfill/stable'
import 'fullscreen-api-polyfill'
import 'whatwg-fetch'

import './i18n'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { App } from './App'
import { getConfig } from './config'

async function start() {
  const config = await getConfig()

  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    enabled: config.environment !== 'dev',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })

  ReactDOM.render(<App />, document.getElementById('root'))
}

start()
