import { useEffect, useState } from 'react'
import { useAuth } from 'src/code-auth/useAuth'
import { DEFAULT_SLIDE_DURATION_MS } from 'src/constants'
import { ScreenContent } from 'src/types'
import { screenService } from '../service/screen'

export function useScreen() {
  const { accessToken, onUnauthenticated } = useAuth()
  const [screenContent, setScreenContent] = useState<ScreenContent | null>(null)

  useEffect(() => {
    screenService.on('unauthenticated', onUnauthenticated)

    return () => {
      screenService.off('unauthenticated', onUnauthenticated)
    }
  }, [onUnauthenticated])

  useEffect(() => {
    screenService.on('screenContent', setScreenContent)

    return () => {
      screenService.off('screenContent', setScreenContent)
    }
  }, [setScreenContent])

  useEffect(() => {
    screenService.setAccessToken(accessToken)
  }, [accessToken])

  return {
    screenId: screenContent?.screenId ?? null,
    slides: screenContent?.slides ?? null,
    slideDurationMs:
      screenContent?.slideDurationMs ?? DEFAULT_SLIDE_DURATION_MS,
  }
}
