import React from 'react'
import { useContext } from 'react'
import { createGlobalStyle, ThemeContext } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 1.2vmin;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  @media (orientation: landscape) {
    html {
      font-size: 1vmin;
    }
  }

  :focus {
    outline: none;
  }

  body {
    background: #000;
    color: ${({ theme: { colors } }) => colors.darkBlue};
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 1rem;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`

export function GlobalStyleHelper() {
  const theme = useContext(ThemeContext)

  return <GlobalStyle theme={theme} />
}
