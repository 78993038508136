import PersistenceService from 'src/service/persistence'
import { DeviceCodeInfoResponse } from 'src/types'

const persistenceService = new PersistenceService()

const STORE_KEY = 'deviceCodeInfo'

export async function getDeviceCodeInfo(): Promise<DeviceCodeInfoResponse | null> {
  return persistenceService.get(STORE_KEY)
}

export async function deleteDeviceCodeInfo() {
  return persistenceService.delete(STORE_KEY)
}

export async function saveDeviceCodeInfo(
  deviceCodeInfo: DeviceCodeInfoResponse
) {
  return persistenceService.save(STORE_KEY, deviceCodeInfo)
}
